import { FolderHeartIcon } from 'lucide-react'
import React from 'react'
import { cn } from '#app/utils/misc.js'

export const Logo = React.forwardRef<HTMLDivElement, { className?: string }>(
	({ className }, ref) => {
		return (
			<div
				ref={ref}
				className={cn(
					'flex content-start items-center gap-3 font-display font-bold text-primary',
					className,
				)}
			>
				<FolderHeartIcon />
				<span className="">Social Saves</span>
			</div>
		)
	},
)

Logo.displayName = 'Logo'
